import { useCallback, useEffect, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'

import API from './api'
import { useDates } from './preference-hooks'

const usePauseDevice = ({
  folderId,
  device,
  timezone,
}: {
  folderId: number | undefined
  device: IDeviceType
  timezone?: string
}) => {
  const handleError = useErrorHandler()
  const [folderTimezone, setFolderTimezone] = useState<string>(timezone ?? '')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { formatDateShortMonth, formatDateTimeShortMonth, formatTime, timezoneAbbr } = useDates(folderTimezone)
  const { mute, pauseStartDate, pauseEndDate } = device || {}

  useEffect(() => {
    setFolderTimezone(timezone ?? '')
  }, [timezone])

  const fetchFolder = useCallback(async () => {
    if (folderId && !timezone) {
      try {
        const res = await API.get(`/api/v2/protect/folders/${folderId}`)
        setFolderTimezone(res.data.folder.timezone)
      } catch (error) {
        handleError(error)
      }
    }
  }, [folderId, handleError, timezone])

  const onRefresh = useCallback(async () => {
    setIsLoading(true)
    await fetchFolder()
    setIsLoading(false)
  }, [fetchFolder])
  useEffect(() => {
    onRefresh()
  }, [onRefresh])

  const isPausedIndefinitely = mute
  const now = new Date()
  const startDate = new Date(pauseStartDate)
  const endDate = new Date(pauseEndDate)
  const isPaused = (startDate < now && endDate > now) || isPausedIndefinitely
  const willBePaused = startDate > now
  const endsAtMidnight = formatTime(pauseEndDate) === '23:59' || formatTime(pauseEndDate) === '11:59 PM'
  const startsAtMidnight = formatTime(pauseStartDate) === '00:00' || formatTime(pauseStartDate) === '12:00 AM'

  let pausedText = ''

  // Is paused indefinitely
  if (isPausedIndefinitely) {
    pausedText = 'This device is paused.'
  } else {
    // Currently paused, only show when pause will end
    if (startDate < now && endDate > now) {
      const formattedEndDate = formatDateTimeShortMonth(pauseEndDate, true)
      pausedText = `Paused until ${formattedEndDate} - ${timezoneAbbr}`
    }
    // Will be paused, show start and end of pause
    if (startDate > now && endDate > now) {
      if (endsAtMidnight) {
        const formattedStartDate =
          (startsAtMidnight ? formatDateShortMonth(pauseStartDate) : formatDateTimeShortMonth(pauseStartDate, true)) ??
          ''
        const formattedEndDate = formatDateShortMonth(pauseEndDate) ?? ''
        pausedText = `Paused from ${formattedStartDate} through the end of ${formattedEndDate} - ${timezoneAbbr}`
      } else {
        const formattedStartDate = formatDateTimeShortMonth(pauseStartDate, true) ?? ''
        const formattedEndDate = formatDateTimeShortMonth(pauseEndDate, true) ?? ''
        pausedText = `Paused from ${formattedStartDate} until ${formattedEndDate} - ${timezoneAbbr}`
      }
    }
  }

  return {
    isPaused,
    willBePaused,
    isPausedIndefinitely,
    pausedText,
    folderTimezone,
    pauseStartDate,
    pauseEndDate,
    onRefresh,
    isLoading,
  }
}

export default usePauseDevice
