import styled from 'styled-components/macro'

import { StyledTd, Td } from '../../../../shared-styles/table.styles'

export const DeviceHeaderInformation = styled.div`
  text-align: center;
`

export const DeviceImageContainer = styled.div`
  background-color: ${({ theme }: ThemeProps) => theme.colors.ui03};
  border-radius: 10rem;
  width: 5.3rem;
  height: 5.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem auto;

  > svg {
    position: relative;
    bottom: 0;
    right: 0;
    width: 3.2rem;
    height: 3.2rem;
  }
`

export const DeviceHeader = styled.h3`
  color: ${({ theme }: ThemeProps) => theme.colors.text01};
  font-family: ${({ theme }: ThemeProps) => theme.fontFamily};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.semibold};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  letter-spacing: 1px;
  line-height: 1.2;
  text-transform: uppercase;
`

export const DeviceInfo = styled.p`
  margin: 0;
`

interface StatusRowProps {
  theme: ThemeProps
  noButton?: boolean
  centerText?: boolean
}

export const StatusRow = styled(Td)<StatusRowProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ noButton }: StatusRowProps) => {
    if (noButton) {
      return `
        justify-content: flex-start;
      `
    }
  }}

  ${({ centerText }: StatusRowProps) => {
    if (centerText) {
      return `
        justify-content: center;
      `
    }
  }}

  div {
    display: flex;
    align-items: center;
  }
  svg,
  span {
    display: block;
  }
  svg {
    margin-right: 1rem;
  }
`

export const StatusTime = styled.span`
  display: block;
  color: ${({ theme }: ThemeProps) => theme.colors.text03};
`

export const DataRow = styled.tr`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.s}) {
    display: flex;
    justify-content: space-between;
  }
`

export const DataCellTd = styled(StyledTd)`
  box-sizing: border-box;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;
  position: relative;

  &:nth-child(1),
  &:nth-child(3) {
    border-right: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
  }
  &:nth-child(3),
  &:nth-child(4) {
    &:before {
      content: '';
      display: block;
      border-top: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};
      position: absolute;
      left: 1.6rem;
      top: -1rem;
      width: calc(100% - 1.5rem);
    }
  }
  &:nth-child(4):before {
    left: 0;
    right: 1.6rem;
  }

  @media (min-width: ${({ theme }: ThemeProps) => theme.breakpoint.s}) {
    border-right: 1px solid ${({ theme }: ThemeProps) => theme.colors.ui04};

    &:nth-child(3),
    &:nth-child(4) {
      &:before {
        display: none;
      }
    }
    &:last-child {
      border-right: 0;
    }
  }
`

export const DataCellHeading = styled.span`
  display: block;
  margin-bottom: 1.8rem;
  color: ${({ theme }: ThemeProps) => theme.colors.text03};
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.xs};
  line-height: 1.2;
`

export const DataCellValue = styled.span`
  display: block;
  font-size: ${({ theme }: ThemeProps) => theme.fontSizes.l};
  color: ${({ theme }: ThemeProps) => theme.colors.text02};
`

export const DataCellOffline = styled.td`
  background: rgba(211, 212, 216, 0.3);
  padding: 0.8rem 2.4rem;
`

export const ShutoffButtonContainer = styled.div`
  margin-bottom: 2.4rem;
  text-align: center;

  button {
    margin: 0;
  }
`

export const ShutoffConfirmationButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    display: block;

    &:first-child {
      font-weight: normal;
    }
    &:nth-child(2) {
      max-width: 14rem;
    }
  }
`
