import styled from 'styled-components/macro'

import { Button } from '../../shared-styles/button.styles'
import { ButtonA } from '../../shared-styles/link.styles'

export const NativeBannerModalContent = styled.p<{ theme: ThemeProps }>`
  text-align: left;
  color: ${({ theme }) => theme.colors.text02};
`

export const NativeBannerModalContentButton = styled(ButtonA)`
  max-width: 100%;
`

export const NativeBannerButton = styled(Button)`
  background: ${({ theme }: ThemeProps) => theme.colors.ui01};
  color: ${({ theme }: ThemeProps) => theme.colors.brand01};
  margin: 0 1.5rem 0 0;
`

export const NativeBannerCloseButton = styled.button`
  background: transparent;
  border: 0;

  &:hover {
    cursor: pointer;
  }
`

export const NativeBannerLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const NativeBannerRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const NativeAppButtonStyled = styled(Button)`
  margin: 0 auto;
  border-radius: 1.6rem;

  @media (max-width: 767px) {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    padding: 4px 8px;
    width: auto;
    text-transform: none;
    white-space: nowrap;
  }
  @media (min-width: 768px) {
    border-radius: 0.4rem;
  }
`

export const NativeAppButtonDesktop = styled.div`
  display: none;

  @media (min-width: 992px) {
    display: inline-block;
  }
`

export const NativeAppButtonMobile = styled.div`
  display: inline-block;

  @media (min-width: 992px) {
    display: none;
  }
`

export const NativeAppLogoContainer = styled.div`
  display: flex;
  gap: 48px;
`
