import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { DeviceResponseTypes } from '..'
import Alert from '../../../components/alert'
import { isLeakSensor, isMotionSensor, isProbeSensor } from '../../../utils/helpers'
import GatewayInstructionCards from '../components/instruction-cards/gateway'
import NBIOTInstructionCards from '../components/instruction-cards/nbiot'
import PipeInstructionCards from '../components/instruction-cards/pipe'
import TempInstructionCards from '../components/instruction-cards/temp'
import V3InstructionCards from '../components/instruction-cards/v3'
import WaterInstructionCards from '../components/instruction-cards/water'
import {
  TroubleshootingPanelConnectionError as ConnectionErrorBVS,
  TroubleshootingPanelLowSignal as LowSignalBVS,
} from '../components/troubleshooting-panels/bvs'
import {
  TroubleshootingPanelConnectionError as ConnectionErrorGateway,
  TroubleshootingPanelLowSignalError as LowSignalGateway,
  TroubleshootingPanelOnBatteryError as OnBatteryErrorGateway,
} from '../components/troubleshooting-panels/gateway'
import {
  TroubleshootingPanelConnectionError as ConnectionErrorLora,
  TroubleshootingPanelLowBattery as LowBatteryLora,
  TroubleshootingPanelLowSignal as LowSignalLora,
} from '../components/troubleshooting-panels/lora'
import {
  TroubleshootingPanelConnectionError as ConnectionErrorLoraV3,
  TroubleshootingPanelLowBattery as LowBatteryLoraV3,
  TroubleshootingPanelLowSignal as LowSignalLoraV3,
} from '../components/troubleshooting-panels/lora-v3'
import {
  TroubleshootingPanelConnectionError as ConnectionErrorNbiot,
  TroubleshootingPanelLowBattery as LowBatteryNbiot,
  TroubleshootingPanelLowSignal as LowSignalNbiot,
} from '../components/troubleshooting-panels/nbiot'
import ModalContext from '../modal-context'

const InstructionParagraph = styled.p`
  margin-top: 0;
`

interface IProps {
  device: IDeviceType
  sensorResponses: DeviceResponseTypes[]
  addSensorResponse: (response: DeviceResponseTypes) => void
}

export const Step2ActivateDevice = ({ device, sensorResponses, addSensorResponse }: IProps) => {
  const TIMEOUT = device.nodeTypeName === 'gateway' ? 5 * 60 * 1000 : 1 * 60 * 1000 // gateway should time out after 5 minutes, sensors after 1 minute

  const { setShowModal, setModalIndex } = useContext(ModalContext)

  const [timerExpireDate, setTimerExpireDate] = useState<Date | undefined>()
  const [timeLeftInSeconds, setTimeLeftInSeconds] = useState<number>(TIMEOUT / 1000)

  useEffect(() => {
    // Scroll to top when the component first mounts
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    // We have a "waiting" status, kick off the timer
    if (sensorResponses.length === 0)
      setTimerExpireDate(prevValue => (prevValue ? prevValue : new Date(Date.now() + TIMEOUT)))
  }, [TIMEOUT, sensorResponses.length])

  useEffect(() => {
    // If the sensor is offline (connection error), open the troubleshooting pane automatically
    if (sensorResponses.includes('offline')) {
      setShowModal(true)
      setModalIndex('connection_error')
    }
  }, [sensorResponses, setShowModal, setModalIndex])

  // Handle timeout
  // When the timerExpireDate is set at the end of updateDeviceStatus(), kick off a timer that checks how much time is left at intervals
  // When the timer expires, if sensorResponses is still empty, add the offline error and clear the timer
  useEffect(() => {
    const timer = setInterval(() => {
      if (timerExpireDate) {
        const difference = timerExpireDate.getTime() - new Date().getTime()
        const seconds = Math.floor(difference / 1000)
        setTimeLeftInSeconds(seconds)
        if (seconds <= 0) clearInterval(timer)
      }
    }, 1000)

    if (!timerExpireDate) clearInterval(timer) // just a bit of cleanup so we don't end up with a useless timer if timerExpireDate is undefined; we don't expect this to happen, but theoretically it could ¯\_(ツ)_/¯

    return () => {
      // clear the timer when the component unmounts
      clearInterval(timer)
    }
  }, [timerExpireDate])

  // Timeout (connection error)
  useEffect(() => {
    if (timeLeftInSeconds <= 0) {
      // Alert will be "Waiting for device to communicate" until the following
      if (sensorResponses.length === 0) addSensorResponse('offline')
    }
  }, [timeLeftInSeconds, sensorResponses, addSensorResponse])

  return (
    <>
      <div>
        <InstructionParagraph>
          Follow the instructions to activate your devices. The Device Status will update once a connection attempt has
          been made.
        </InstructionParagraph>
        {device.nodeTypeName === 'gateway' && (
          <Alert
            type="custom"
            backgroundcolor="rgba(51, 135, 178, 0.2)"
            icon="solid_info"
            message="Gateway must be activated first for other devices to communicate."
          />
        )}
      </div>
      <h4>Device Status:</h4>
      {sensorResponses.length === 0 && <Alert type="offline" icon="wait" message="Waiting for device to communicate" />}
      {sensorResponses.length > 0 &&
        sensorResponses.map((sensorResponse, index) => {
          switch (sensorResponse) {
            case 'online':
              return <Alert type="success" icon="check" message="Device communicating regularly." key={index} />
            case 'offline':
              return (
                <div key={index}>
                  <Alert
                    type="error"
                    icon="error"
                    message="Connection error."
                    actions={[
                      {
                        text: 'Troubleshoot device.',
                        onClick: () => {
                          setShowModal(true)
                          setModalIndex('connection_error')
                        },
                      },
                    ]}
                  />
                  {device.nodeTypeName === 'gateway' ? (
                    <ConnectionErrorGateway />
                  ) : device.nodeTypeName === 'nbiot_leak' ? (
                    <ConnectionErrorNbiot />
                  ) : device.nodeTypeName === 'temp3' || device.nodeTypeName === 'leak3' ? (
                    <ConnectionErrorLoraV3 />
                  ) : device.nodeTypeName === 'bvs' || device.nodeTypeName === 'bvs2' ? (
                    <ConnectionErrorBVS />
                  ) : (
                    <ConnectionErrorLora />
                  )}
                </div>
              )
            case 'on_batt':
              return (
                <div key={index}>
                  <Alert
                    type="warning"
                    icon="warning"
                    message="Device switched to back-up battery due to loss of power."
                    actions={[
                      {
                        text: 'Troubleshoot device.',
                        onClick: () => {
                          setShowModal(true)
                          setModalIndex('on_batt')
                        },
                      },
                    ]}
                  />
                  <OnBatteryErrorGateway />
                </div>
              )
            case 'low_batt':
              return (
                <div key={index}>
                  <Alert
                    type="warning"
                    icon="warning"
                    message="Low battery. You may continue activation or"
                    actions={[
                      {
                        text: 'troubleshoot device.',
                        onClick: () => {
                          setShowModal(true)
                          setModalIndex('low_battery')
                        },
                      },
                    ]}
                  />
                  {device.nodeTypeName === 'gateway' ? (
                    <OnBatteryErrorGateway />
                  ) : device.nodeTypeName === 'nbiot_leak' ? (
                    <LowBatteryNbiot />
                  ) : device.nodeTypeName === 'temp3' || device.nodeTypeName === 'leak3' ? (
                    <LowBatteryLoraV3 />
                  ) : device.nodeTypeName === 'bvs' || device.nodeTypeName === 'bvs2' ? (
                    <></>
                  ) : (
                    <LowBatteryLora type={device.nodeTypeName} />
                  )}
                </div>
              )
            case 'low_signal':
              return (
                <div key={index}>
                  <Alert
                    type="warning"
                    icon="warning"
                    message="Poor signal. You may continue activation or"
                    actions={[
                      {
                        text: 'troubleshoot device.',
                        onClick: () => {
                          setShowModal(true)
                          setModalIndex('low_signal')
                        },
                      },
                    ]}
                  />
                  {device.nodeTypeName === 'gateway' ? (
                    <LowSignalGateway />
                  ) : device.nodeTypeName === 'nbiot_leak' ? (
                    <LowSignalNbiot />
                  ) : device.nodeTypeName === 'temp3' || device.nodeTypeName === 'leak3' ? (
                    <LowSignalLoraV3 />
                  ) : device.nodeTypeName === 'bvs' || device.nodeTypeName === 'bvs2' ? (
                    <LowSignalBVS />
                  ) : (
                    <LowSignalLora />
                  )}
                </div>
              )
            default:
              return null
          }
        })}
      <h4>Instructions:</h4>
      {device.nodeTypeName === 'nbiot_leak' && <NBIOTInstructionCards />}
      {isMotionSensor(device.nodeTypeName) && device.nodeTypeName !== 'temp3' && <TempInstructionCards />}
      {isLeakSensor(device.nodeTypeName) && device.nodeTypeName !== 'leak3' && <WaterInstructionCards />}
      {(device.nodeTypeName === 'temp3' || device.nodeTypeName === 'leak3') && <V3InstructionCards />}
      {isProbeSensor(device.nodeTypeName) && <PipeInstructionCards />}
      {device.nodeTypeName === 'gateway' && <GatewayInstructionCards />}
    </>
  )
}

export default Step2ActivateDevice
