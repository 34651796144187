import '@reach/dialog/styles.css'
import { navigate, RouteComponentProps } from '@reach/router'
import { VisuallyHidden } from '@reach/visually-hidden'
import axios, { AxiosError, CancelTokenSource } from 'axios'
import { Formik } from 'formik'
import moment from 'moment'
import queryString from 'query-string'
import { useContext, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'

import ProtectLiteLogoMobile from '../../assets/protect-lite-logo-mobile.svg'
import ProtectLiteLogo from '../../assets/protect-lite-logo.svg'
import Alert from '../../components/alert'
import { InlineLanguagePicker } from '../../components/language-pickers/index'
import PasswordField from '../../components/password-field'
import ResetPasswordModal, { ResetFormValues } from '../../components/reset-password-modal'
import SupportModal from '../../components/support-modal'
import AuthContext from '../../contexts/auth-context'
import { Error } from '../../shared-styles/alert.styles'
import { LinkButton } from '../../shared-styles/button.styles'
import { CenteredButtonWrapper, FormField, StyledForm } from '../../shared-styles/form.styles'
import { DesktopOnly, MobileOnly } from '../../shared-styles/responsive.styles'
import {
  MobileSplash,
  Splash,
  SplashAlertContainer,
  SplashButtonContainer,
  SplashContentContainer,
  SplashEndNote,
  SplashFooter,
  SplashFooterButton,
  SplashFooterLinks,
  SplashGrid,
  SplashIntroText,
  SplashLanguageContainer,
  SplashPageTitle,
} from '../../shared-styles/splashscreen.styles'
import { A } from '../../shared-styles/typography.styles'
import API from '../../utils/api'
import { PRIVACY_POLICY } from '../../utils/constants'
import { Btn } from './../../components/button/index'
import { FormFieldInput } from './../../components/form-field-input/index'
import { SplashIntroOverlay } from './../../shared-styles/splashscreen.styles'
import { TERMS_AND_CONDITIONS } from './../../utils/constants'

interface FormValues {
  email: string
  password: string
}

const ValidationSchema = Yup.object().shape({
  email: Yup.string().required('Please enter your email.').email('Please enter correct email format.'),
  password: Yup.string().required('Please enter your password.'),
})

const Login = (props: RouteComponentProps) => {
  const _isMounted = useRef(true)
  const { setIsAuth, userToImpersonate } = useContext(AuthContext)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [errorCount, setErrorCount] = useState<number>(0)
  const [bounceError, setBounceError] = useState(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [dialogTitle, setDialogTitle] = useState<string>('Reset Password')
  const [showSupportDialog, setShowSupportDialog] = useState<boolean>(false)
  const [resetEmailSent, setResetEmailSent] = useState<string | undefined>()
  const [actionSuccessfulMsg, setActionSuccessfulMsg] = useState<string | undefined>()
  // This allow us to cancel an axios call in the useEffect cleanup function when unmounting the component
  const [source] = useState<CancelTokenSource>(axios.CancelToken.source())

  // Clean up when the component unmounts
  useEffect(() => {
    return () => {
      // clean up axios calls when component unmounts
      source.cancel()
      _isMounted.current = false // used to handle memory leaks when performing a state changing when the component has already unmounted
    }
  }, [source])

  // Handle successful reset password message after redirect from reset-password page
  // Handle successful account creation message after redirect from set-password page
  // Handle success log out message
  useEffect(() => {
    if (props.location) {
      const queryParams = queryString.parse(props.location.search)
      if (queryParams.message === 'reset-password-successful') {
        setActionSuccessfulMsg('Password successfully reset.')
      } else if (queryParams.message === 'account-creation-successful') {
        setActionSuccessfulMsg('Account successfully created.')
      } else if (queryParams.message === 'log-out-successful') {
        setActionSuccessfulMsg('You have been logged out.')
      }
    }
  }, [props.location])

  const onResetPasswordSubmit = async ({ resetEmail }: ResetFormValues) => {
    // Call API to send reset e-mail
    const res = await API.get(`/api/passwords/resetpassword?email=${encodeURIComponent(resetEmail)}`, source.token)
    if (res.status === 200) {
      setResetEmailSent(resetEmail)
    }
    setShowDialog(false)
  }

  const displayApiError = (type: 'system' | 'connection' | 'invalid') => {
    let newErrorMessage
    switch (type) {
      case 'system':
        newErrorMessage = 'Something was wrong in our system.'
        break
      case 'connection':
        newErrorMessage = 'There is no internet connection. Please check your network.'
        break
      case 'invalid':
        newErrorMessage = 'Invalid username or password.'
        break
    }

    setIsAuth(false)
    setErrorMessage(newErrorMessage)
    setErrorCount(errorCount + 1)
    if (errorCount > 0) setBounceError(true)
  }

  const onSubmit = async ({ email, password }: FormValues) => {
    setActionSuccessfulMsg(undefined)
    setResetEmailSent(undefined)
    const b64 = btoa(`${email}:${password}`)
    const headers = { Authorization: `Basic ${b64}` }
    try {
      let newUser = await API.get('/api/login', { headers })
      if (userToImpersonate && newUser.data.id !== Number(userToImpersonate)) {
        newUser = await API.get(`/api/users/${userToImpersonate}/impersonate`)
      }
      localStorage.setItem('protectToken', 'meshify-authenticated')
      setIsAuth(true)
      navigate('/login-redirect')
    } catch (e) {
      if (!window.navigator.onLine) {
        displayApiError('connection')
      } else if ((e as AxiosError).response?.status === 500) {
        displayApiError('system')
      } else if ((e as AxiosError).response?.status === 401) {
        displayApiError('invalid')
      } else {
        displayApiError('system')
      }
    }
    if (_isMounted.current) setTimeout(() => setBounceError(false), 1000)
  }

  return (
    <SplashGrid>
      <MobileSplash>
        <div>
          <SplashPageTitle>
            <img src={ProtectLiteLogoMobile} alt="Meshify Protect Lite" />
          </SplashPageTitle>
        </div>
      </MobileSplash>
      <Formik initialValues={{ email: '', password: '' }} validationSchema={ValidationSchema} onSubmit={onSubmit}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
          <SplashContentContainer>
            <DesktopOnly>
              <SplashPageTitle>
                <img src={ProtectLiteLogo} alt="Meshify Protect Lite" data-cy="loginPageTitle" />
              </SplashPageTitle>
            </DesktopOnly>
            <SplashIntroText>Log in with your email & password used to create your account.</SplashIntroText>
            {actionSuccessfulMsg && (
              <SplashAlertContainer>
                <Alert type="info_success" icon="check_nocircle" message={actionSuccessfulMsg} />
              </SplashAlertContainer>
            )}
            {resetEmailSent && (
              <FormField style={{ marginBottom: '-2.4rem' }}>
                <Alert
                  type="info_support"
                  icon="check_nocircle"
                  iconAlignment="top"
                  message={'If the account exists, an email will be sent with further instructions'}
                />
              </FormField>
            )}
            {errorMessage && (
              <Error className={bounceError ? 'animated bounce' : ''} data-cy="loginValidationErrorMessage">
                {errorMessage}
              </Error>
            )}
            <StyledForm onSubmit={handleSubmit}>
              <FormFieldInput
                id="email"
                type="email"
                label="Email"
                formikData={{ values, touched, errors, handleChange, handleBlur }}
                errorState={!!(touched.email && errors.email) || !!errorMessage}
                data-cy="email"
                errorDataCy="usernameValidationMsg"
              />
              <PasswordField
                name="password"
                label="Password"
                onChange={value => setFieldValue('password', value)}
                onBlur={handleBlur}
                errorState={!!(touched.password && errors.password) || !!errorMessage}
                formikData={{ touched, errors }}
              />
              <CenteredButtonWrapper>
                <Btn
                  type="submit"
                  data-testid="submit_login"
                  data-cy="logInBtn"
                  fullwidth={true}
                  waiting={isSubmitting}
                >
                  Log In
                </Btn>
              </CenteredButtonWrapper>
            </StyledForm>
            <SplashButtonContainer>
              <CenteredButtonWrapper>
                <LinkButton
                  onClick={() => {
                    setShowDialog(true)
                    setDialogTitle('Reset Password')
                  }}
                  data-cy="forgotPassword"
                >
                  Forgot password?
                </LinkButton>
              </CenteredButtonWrapper>
            </SplashButtonContainer>
            <SplashEndNote>
              Don't have an account?
              <br />
              <LinkButton
                onClick={() => {
                  setShowDialog(true)
                  setDialogTitle('Register')
                }}
              data-cy="registerBtn">
                Register
              </LinkButton>
            </SplashEndNote>
            <SplashLanguageContainer>
              <InlineLanguagePicker />
            </SplashLanguageContainer>
            <MobileOnly>
              <SplashFooter>
                <SplashFooterLinks>
                  <A href={PRIVACY_POLICY} target="_blank" rel="noopener">
                    Privacy <VisuallyHidden>(PDF)</VisuallyHidden>
                  </A>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <A href={TERMS_AND_CONDITIONS} target="_blank" rel="noopener">
                    Terms <VisuallyHidden>(PDF)</VisuallyHidden>
                  </A>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <SplashFooterButton onClick={() => setShowSupportDialog(true)}>
                    <b>Support</b>
                  </SplashFooterButton>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <span>©{moment().format('Y')} Meshify, Inc.</span>
                </SplashFooterLinks>
              </SplashFooter>
            </MobileOnly>
          </SplashContentContainer>
        )}
      </Formik>
      <ResetPasswordModal
        title={dialogTitle}
        openModal={showDialog}
        onDismiss={() => setShowDialog(false)}
        onResetPasswordSubmit={onResetPasswordSubmit}
      />
      <SupportModal openModal={showSupportDialog} onDismiss={() => setShowSupportDialog(false)} />
      <Splash>
        <DesktopOnly>
          <SplashIntroOverlay>
            <p>
              Welcome to Protect Lite! An all new web portal for activating sensors and monitoring your location, even
              when you’re away.
            </p>
            <p>Note: For legacy users, Protect Lite replaces Enrollment Core / MySensors as your web-based portal.</p>
          </SplashIntroOverlay>
          <SplashFooter>
            <div>©{moment().format('Y')} Meshify, Inc.</div>
            <SplashFooterLinks>
              <A href={PRIVACY_POLICY}>
                Privacy <VisuallyHidden>(PDF)</VisuallyHidden>
              </A>
            </SplashFooterLinks>
            <SplashFooterLinks>
              <A href={TERMS_AND_CONDITIONS}>
                Terms of Service <VisuallyHidden>(PDF)</VisuallyHidden>
              </A>
            </SplashFooterLinks>
            <SplashFooterLinks>
              <SplashFooterButton onClick={() => setShowSupportDialog(true)}>Support</SplashFooterButton>
            </SplashFooterLinks>
            <div>Sensor Solutions by HSB™️</div>
          </SplashFooter>
        </DesktopOnly>
      </Splash>
    </SplashGrid>
  )
}

export default Login
