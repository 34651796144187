import GetAppQR from '../../assets/download_protect_QR.svg'
import ProtectLogo from '../../assets/meshfiy_protect.svg'
import { DesktopOnly, MobileOnly } from '../../shared-styles/responsive.styles'
import { A } from '../../shared-styles/typography.styles'
import Modal from '../modal'
import {
  NativeAppLogoContainer,
  NativeBannerModalContent,
  NativeBannerModalContentButton,
} from './activate-native-app-modal.styles'

const ActivateNativeAppModal = ({
  openModal,
  setOpenModal,
}: {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <Modal
      title="Activate with Protect App"
      openModal={openModal}
      onDismiss={() => setOpenModal(false)}
      roundedCorners={true}
      shadows={true}
      showTitleAboveDivider={true}
      showDivider={true}
      showDividerMobile={true}
      maxWidth="46.7rem"
      margin="30vh auto"
    >
      <MobileOnly>
        <NativeBannerModalContent>
          To proceed, download our app. The app is required to activate devices.{' '}
          <A href="https://meshify.com/resources/" target="_blank" rel="noopener noreferrer">
            More info
          </A>
        </NativeBannerModalContent>
      </MobileOnly>
      <DesktopOnly>
        <NativeBannerModalContent>
          To proceed, scan the QR code below and download our app. The app is required to activate devices.{' '}
          <A href="https://meshify.com/resources/" target="_blank" rel="noopener noreferrer">
            More info
          </A>
        </NativeBannerModalContent>
      </DesktopOnly>
      <MobileOnly>
        <p>
          <NativeBannerModalContentButton href="https://meshifyprotect.app.link/app">
            Download App
          </NativeBannerModalContentButton>
        </p>
      </MobileOnly>
      <DesktopOnly>
        <NativeAppLogoContainer>
          <img src={ProtectLogo} alt="Meshify Protect Logo" width="91" height="93" />
          <img src={GetAppQR} alt="QR Code" width="96" height="96" />
        </NativeAppLogoContainer>
      </DesktopOnly>
      <NativeBannerModalContent>
        Once activated, you can use either the mobile app or the web portal to manage accounts, monitor devices, and
        more.
      </NativeBannerModalContent>
    </Modal>
  )
}

export default ActivateNativeAppModal
