import { navigate, RouteComponentProps, useParams } from '@reach/router'
import axios, { CancelTokenSource } from 'axios'
import { debounce } from 'lodash'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary'

import NoDevicesImg from '../../assets/no-devices.svg'
import NativeAppBanner, { NativeAppButton } from '../../components/header/native-app-banner'
import { HamburgerMenuIcon } from '../../components/icon'
import { AppContext } from '../../contexts/app-context'
import UserContext from '../../contexts/user-context'
import API from '../../utils/api'
import { errorHandler, getDevices, getLogo } from '../../utils/helpers'
import { UpdateStateProps, useWebsocket } from '../../utils/websockets'
import ErrorScreen from './../../components/error-screen/index'
import {
  ContentSection,
  MainContentSection,
  MenuIconButtonWrapper,
  MenuLogoWrapper,
  MenuNativeButtonWrapper,
  NoDevicesContainer,
  PageLayout,
  VanishingNavBar,
} from './dashboard.styles'
import FloatingSidebar from './modals/floating-sidebar'
import Devices from './sections/devices'
import Filters from './sections/filters'
import { FiltersErrored } from './sections/filters'
import Header from './sections/header'
import Sidebar from './sections/sidebar'

interface WeatherProps {
  temperature: number
  highTemp: number
  lowTemp: number
  weatherdescription: string
}

export interface WelcomeDataProps {
  hasAlerts: boolean
  hasIncompleteChildren: boolean
  hasCompleteChildren: boolean
  isOnboarded: boolean
  isActivated: boolean
  isPartiallyActivated: boolean
  weather: WeatherProps | undefined
}

const Dashboard = (_: RouteComponentProps) => {
  const _isMounted = useRef(true)
  const { folders, setFolders, foldersLoading, userIsLocationManager } = useContext(AppContext)
  const { folderId }: { folderId: string } = useParams()
  const handleError = useErrorHandler()
  const prevFolderId = useRef<number | undefined>()
  // This allow us to cancel an axios call
  const axiosSource = useRef<CancelTokenSource>(axios.CancelToken.source())
  const source = axiosSource.current

  const [folderAlarms, setFolderAlarms] = useState<IAlertHistory[]>([])
  const [allDevices, setAllDevices] = useState<IDeviceType[] | undefined>()
  const [devicesLoaded, setDevicesLoaded] = useState<boolean>(false)
  const [welcomeData, setWelcomeData] = useState<WelcomeDataProps>()
  const [groupType, setGroupType] = useState<'type' | 'location' | 'none' | undefined>()
  const [displayType, setDisplayType] = useState<'all' | 'alertsonly'>('all')
  const [companyBranding, setCompanyBranding] = useState<IFolderTheme>()
  const [themeLoading, setThemeLoading] = useState<boolean>(true)

  // To toggle sidebar when menu button is clicked on mobile devices:
  const [displayFloatingSidebar, setDisplayFloatingSidebar] = useState<boolean>(false)

  const [selectedDeviceId, setSelectedDeviceId] = useState<number | undefined>(undefined)

  const selectedLocation = useMemo(() => {
    const foundFolder = folders.find(f => f.id === Number(folderId))

    if (welcomeData && foundFolder) {
      foundFolder.isOnboarded = welcomeData.isOnboarded
      foundFolder.isActivated = welcomeData.isActivated
    }

    return foundFolder
  }, [welcomeData, folderId, folders])

  const selectedFolderId = selectedLocation?.id

  const devices = useMemo(() => allDevices?.filter(d => d.isActivated), [allDevices])
  const notActivatedDevices = useMemo(() => allDevices?.filter(d => !d.isActivated), [allDevices])

  // Clean up when the component unmounts
  useEffect(() => {
    return () => {
      source.cancel('cancelled') // clean up axios calls when component unmounts
    }
  }, [source])
  useEffect(() => {
    return () => {
      _isMounted.current = false // used to handle memory leaks when performing a state change when the component has already unmounted
    }
  }, [])

  // Websockets
  const updateState = useCallback((props: UpdateStateProps) => {
    const { payload, channelValue, timestamp, deviceId: messageDeviceId } = props
    const { channelName, value } = channelValue
    const newChannelValue: ICurrentValueType = {}
    newChannelValue[channelName] = {
      createdAt: timestamp,
      timestamp,
      value,
    }
    setAllDevices(
      prev =>
        prev &&
        prev.map(d => {
          if (d.id === messageDeviceId)
            return {
              ...d,
              ...payload,
              currentValues: {
                ...d.currentValues,
                ...newChannelValue,
              } as unknown as ICurrentValueType,
            }
          else return d
        })
    )
  }, [])
  // Handle events that come in through websockets - refetch alerts for the folder
  const debouncedDataFetch = useRef(
    debounce(async () => {
      try {
        const alertsData = (await API.get(`/api/v2/protect/folders/${folderId}/alerts`)).data.alerts as IAlertHistory[]
        if (_isMounted.current && prevFolderId.current === Number(folderId)) {
          setFolderAlarms(alertsData)
          if (alertsData.find(a => a.status === 'active'))
            setFolders(prev => prev.map(f => (f.id === Number(folderId) ? { ...f, hasAlerts: true } : f)))
          else setFolders(prev => prev.map(f => (f.id === Number(folderId) ? { ...f, hasAlerts: false } : f)))
        }
      } catch {
        // Do nothing, this is in the background
      }
    }, 300)
  ).current
  useEffect(() => {
    return () => {
      debouncedDataFetch.cancel()
    }
  }, [debouncedDataFetch])
  const handleEvent = () => {
    setTimeout(debouncedDataFetch, 15000) // 15 seconds to account for a delay between when we receive a websocket message and when alerts on the device endpoint is updated
  }
  useWebsocket({
    shouldBeAlive: _isMounted.current && selectedLocation?.folderType === 'Participant',
    subscribeIds: devices ? devices.filter(d => d.isActivated).map(d => d.id) : [],
    updateState,
    subscribeChannels: ['signal_indicator', 'battery_indicator', 'rssi', 'voltage'],
    handleEvent,
  })

  // Reset data and abort queries when changing locations
  useEffect(() => {
    if (prevFolderId.current !== selectedLocation?.id) {
      source.cancel('cancelled')
      setFolderAlarms([])
      setAllDevices(undefined)
      setDevicesLoaded(false)
      setWelcomeData(undefined)
      prevFolderId.current = selectedLocation?.id
      axiosSource.current = axios.CancelToken.source()
    }
  }, [selectedLocation?.id, source])

  // Get welcome data, folder theme, alerts, and all devices in selected folder
  const fetchData = useCallback(async () => {
    if (selectedFolderId) {
      setAllDevices(undefined)
      setDevicesLoaded(false)
      try {
        // fetch the welcome information
        const welcomeApiData = (
          await API.get(`/api/v2/protect/folders/${selectedFolderId}/welcome`, {
            cancelToken: source.token,
          })
        ).data as WelcomeDataProps
        const hasChildren = welcomeApiData.hasCompleteChildren || welcomeApiData.hasIncompleteChildren

        // Go directly to onboarding if this is a single location that has not yet been onboarded
        if (!hasChildren && !welcomeApiData.isOnboarded && userIsLocationManager) {
          navigate(`/onboarding/1/location/${selectedFolderId}`)
        } else {
          setWelcomeData(welcomeApiData)
          const alertsData = (
            await API.get(`/api/v2/protect/folders/${selectedFolderId}/alerts`, {
              cancelToken: source.token,
            })
          ).data.alerts as IAlertHistory[]

          const foldersFromAlerts = alertsData.map(a => a.folderId)
          const foldersWithAlerts = alertsData.filter(a => a.status === 'active').map(a => a.folderId)
          setFolders(prev =>
            prev.map(f => ({
              ...f,
              // if the alerts api gave us an update specifically about this folder, use the value, otherwise keep the old value
              hasAlerts: foldersFromAlerts.includes(f.id) ? foldersWithAlerts.includes(f.id) : f.hasAlerts,
            }))
          )

          const devicesData = await getDevices(selectedFolderId, false, source)
          setAllDevices(devicesData) // All devices, even those not yet activated
          setFolderAlarms(alertsData)
          setDevicesLoaded(true)
        }
      } catch (e) {
        if (e.message !== 'cancelled') handleError(e)
      }
    }
  }, [selectedFolderId, handleError, setFolders, userIsLocationManager, source])

  const fetchTheme = useCallback(async () => {
    if (selectedFolderId) {
      setThemeLoading(true)
      try {
        // Get the combined theme
        const { theme: apiTheme } = (
          await API.get(`/api/folders/${selectedFolderId}/theme`, {
            cancelToken: source.token,
          })
        ).data
        setCompanyBranding(apiTheme)
        setThemeLoading(false)
      } catch (e) {
        if (e.message !== 'cancelled') handleError(e)
      }
    }
  }, [handleError, selectedFolderId, source])

  useEffect(() => {
    // Get combined theme for folder
    fetchTheme()
    // Only get data is folder is participant (otherwise API will fail)
    if (selectedLocation?.folderType === 'Participant') fetchData()
    else {
      setDevicesLoaded(true)
      setAllDevices([])
    }
  }, [fetchData, fetchTheme, selectedLocation?.folderType])

  // Set initial device grouping
  useEffect(() => {
    if (!groupType && devices && devices.length > 0) {
      if (devices && devices.length < 5) setGroupType('none')
      else if (devices && devices.length >= 5) setGroupType('location')
    }
  }, [devices, groupType])

  return (
    <UserContext.Provider
      value={{
        folderAlarms,
        devices,
        setDevices: setAllDevices,
        devicesLoaded,
        welcomeData,
        displayType,
        setDisplayType,
        groupType,
        setGroupType,
        displayFloatingSidebar,
        setDisplayFloatingSidebar,
        selectedDeviceId,
        setSelectedDeviceId,
        companyBranding,
        themeLoading,
      }}
    >
      <NativeAppBanner />
      <PageLayout>
        <Sidebar showSidebar={true} />
        <MainContentSection>
          <VanishingNavBar>
            <MenuIconButtonWrapper
              onClick={() => {
                setDisplayFloatingSidebar(true)
              }}
            >
              <HamburgerMenuIcon />
            </MenuIconButtonWrapper>
            {companyBranding && (
              <MenuLogoWrapper className="fs-exclude">
                <img src={getLogo(companyBranding)} alt="Company Logo" />
              </MenuLogoWrapper>
            )}
            <MenuNativeButtonWrapper>
              <NativeAppButton />
            </MenuNativeButtonWrapper>
          </VanishingNavBar>
          <ContentSection id="mainContent" aria-live="polite">
            <>
              <Header
                selectedLocation={selectedLocation}
                parentFolder={folders.find(f => f.id === selectedLocation?.parentId)}
                devices={allDevices}
                setSelectedDeviceId={setSelectedDeviceId}
              />

              {!folderId && !foldersLoading ? (
                <NoDevicesContainer>
                  <div>
                    <img src={NoDevicesImg} alt="" />
                    <p>Please select a location to get started.</p>
                  </div>
                </NoDevicesContainer>
              ) : devices && devices.length === 0 && !foldersLoading ? (
                <>
                  <NoDevicesContainer>
                    <div>
                      <img src={NoDevicesImg} alt="" />
                      {(notActivatedDevices?.length || 0) > 0 ? (
                        <p>
                          There are no activated devices in this folder. Please activate devices or select another
                          location associated with this folder from the navigation.
                        </p>
                      ) : (
                        <p>
                          There are no devices in this folder. Please select another location associated with this
                          folder from the navigation.
                        </p>
                      )}
                    </div>
                  </NoDevicesContainer>
                  {/* Adding <Devices /> here allows an empty parent participant to still open the device panel for a child participant when
                  selecting a notification */}
                  <Devices />
                </>
              ) : (
                <ErrorBoundary
                  fallbackRender={({ error }) => (
                    <>
                      <FiltersErrored />
                      <ErrorScreen error={error.message} />
                    </>
                  )}
                  onError={errorHandler}
                >
                  <>
                    <Filters />
                    <Devices />
                  </>
                </ErrorBoundary>
              )}
            </>
          </ContentSection>
          <FloatingSidebar />
        </MainContentSection>
      </PageLayout>
    </UserContext.Provider>
  )
}

export default Dashboard
