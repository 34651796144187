import countriesData from './countriesData'
import { prettifyPhoneNumber } from './helpers'

const parsePhoneNumber = (phoneNumber: string | undefined, user?: IUserType, folder?: IFolderType) => {
  let parsedNumber: string = ''
  let parsedNumberCountry: string = '1'
  let parsedNumberCountryAbbr: string = 'us'
  let country: string

  if (phoneNumber) {
    countriesData.forEach((countryArr: [string, string, string, number?, string[]?]) => {
      if (phoneNumber?.startsWith('+' + countryArr[2])) {
        parsedNumber = phoneNumber.startsWith('+' + countryArr[2] + ' ')
          ? phoneNumber.replace('+' + countryArr[2] + ' ', '') // ex: +1 123 456 7890 or +1 123-456-7890 or +1 (123) 456-7890
          : phoneNumber.replace('+' + countryArr[2], '') // ex: +11234567890
        parsedNumberCountry = countryArr[2]
        // +1 may not always be US, check the first 3 digits of the number
        if (
          (countryArr[2] === '1' && !!countryArr[4]?.includes(parsedNumber.substring(0, 3))) ||
          countryArr[2] !== '1'
        ) {
          parsedNumberCountryAbbr = countryArr[1]
        }
      }
    })
    if (!parsedNumber) parsedNumber = phoneNumber
  } else {
    // Check user's information.address.country
    if (user?.information?.address?.country) {
      country = user.information.address.country
    }
    // If nothing helps us there, check the folder's information.address.country
    else if (folder?.information?.address?.country) {
      country = folder.information.address.country
    }

    countriesData.forEach((countryArr: [string, string, string, number?, string[]?]) => {
      if (countryArr[0] === country) {
        parsedNumberCountry = countryArr[2]
        parsedNumberCountryAbbr = countryArr[1]
      }
    })
  }

  return {
    number: parsedNumber ? prettifyPhoneNumber(`+${parsedNumberCountry} ${parsedNumber}`) : '',
    numberNoCountry: parsedNumber ? prettifyPhoneNumber(parsedNumber) : '',
    countryCode: parsedNumberCountry,
    countryAbbr: parsedNumberCountryAbbr,
  }
}

export default parsePhoneNumber
