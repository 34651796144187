import { navigate, RouteComponentProps, useParams } from '@reach/router'
import axios, { CancelTokenSource } from 'axios'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary'

import Step1 from '../../assets/activation-tutorial/step1.svg'
import Step2 from '../../assets/activation-tutorial/step2.svg'
import Step3 from '../../assets/activation-tutorial/step3.svg'
import SensorSerialInfo from '../../assets/SensorSerialInfo.jpg'
import Alert from '../../components/alert'
import Btn from '../../components/button'
import ErrorBoundaryFallback from '../../components/error-screen/error-boundary'
import GlowCallout from '../../components/glow-callout'
import Header from '../../components/header'
import Modal from '../../components/modal'
import ProgressBar from '../../components/progress-bar'
import SensorSelect, { SensorSelectSkeleton } from '../../components/sensor-select'
import Sidepanel from '../../components/sidepanel'
import { SkeletonLine } from '../../components/skeleton-line'
import Tooltip from '../../components/tooltip'
import { AppContext } from '../../contexts/app-context'
import { StyledSkipLinksActivation } from '../../shared-styles/accessibility.styles'
import { LinkButton } from '../../shared-styles/button.styles'
import { DesktopOnly, MobileOnly } from '../../shared-styles/responsive.styles'
import { Body1P, Body2P } from '../../shared-styles/typography.styles'
import theme from '../../theme'
import API from '../../utils/api'
import { DEFAULT_DEVICE_LOCATIONS } from '../../utils/constants'
import {
  convertStringToArray,
  deviceDataIsComplete,
  deviceIsSearching,
  errorHandler,
  getDevices,
  hasMultiFolderAccess,
  isGateway,
  isNbiotSensor,
  isNonNbiotSensor,
  makeEllipsis,
} from '../../utils/helpers'
import { UpdateStateProps, useWebsocket } from '../../utils/websockets'
import { CTAPanelLeft, ExitButton } from '../onboarding/onboarding.styles'
import CarouselTutorial from './../../components/carousel-tutorial'
import { SearchBar } from './../../components/search-bar/index'
import {
  ActivationCTAPanel,
  ActivationCTAPanelRight,
  InstructionalImageContainer,
  LeftAlignParagraph,
  MobileInstructions,
  MobileModalButtonContainer,
  MobileModalCTA,
  ModalCTA,
  NoSearchResults,
  PageLayout,
  SearchContainer,
  SearchResultsHeading,
  SensorSelectContainer,
  Sidebar,
  SidebarHeader,
  SidebarHeaderContainer,
  SidebarSearchSection,
  SidebarSubHeader,
  SidebarSubHeaderNote,
  TooltipContainer,
  TutorialSlide,
} from './activating.styles'
import ActivationContext from './activation-context'
import CompletionPanel from './completion-panel'
import Loading from './loading'
import SensorActivation from './sensor-activation'
import Welcome from './welcome'

export const getDeviceStatus = (device: IDeviceType) => {
  return device.isActivated
    ? 'active'
    : !deviceIsSearching(device) && (!device.deviceUseCase || !device.area || !device.deviceLocationNotes)
    ? 'incomplete'
    : 'inactive'
}

const subscribeChannels = ['signal_indicator', 'battery_indicator', 'powersource', 'rssi', 'voltage']

const ModalContent = ({ completeActivation, folderId }: { completeActivation: () => void; folderId: string }) => {
  const [buttonWaiting, setButtonWaiting] = useState<boolean>(false)

  return (
    <>
      <Body1P mobileBody2={true}>
        You still have devices that need to be activated. How would you like to proceed?
      </Body1P>

      <MobileOnly>
        <MobileModalCTA>
          <Btn
            onClick={() => {
              setButtonWaiting(true)
              completeActivation()
            }}
            waiting={buttonWaiting}
            style={{ marginBottom: 0 }}
          >
            Complete Installation
          </Btn>
          <Body2P style={{ marginBottom: 0 }}>+ Mark Inactive Sensors As Extra</Body2P>
          <Btn buttonType="ghost" onClick={() => navigate(`/dashboard/${folderId}`)}>
            Continue Later
          </Btn>
        </MobileModalCTA>
      </MobileOnly>
      <DesktopOnly>
        <ModalCTA>
          <Btn buttonType="ghost" onClick={() => navigate(`/dashboard/${folderId}`)}>
            Continue Later
          </Btn>
          <Btn
            onClick={() => {
              setButtonWaiting(true)
              completeActivation()
            }}
            waiting={buttonWaiting}
            style={{ maxWidth: '28.8rem' }}
          >
            Complete Installation
          </Btn>
        </ModalCTA>
        <ModalCTA>
          <Body2P style={{ maxWidth: '28.8rem', textAlign: 'center', marginBottom: 0 }}>
            + Mark Inactive Sensors As Extra
          </Body2P>
        </ModalCTA>
      </DesktopOnly>
    </>
  )
}

export type DeviceResponseTypes = 'online' | 'offline' | 'waiting' | 'low_batt' | 'low_signal' | 'on_batt'

export interface UserProps {
  id: number
  roleId: number
  role: string
  email: string
  name: string
  folders: number[]
}

export interface OrderProps {
  delivered_date: string | undefined
}

/** Determine if the Complete Activation button should be enabled or disabled */
const toggleCompleteActivation = (devicesToCheck: IDeviceType[] | undefined) => {
  if (devicesToCheck) {
    // Toggle the Complete Activation button if minimum number of devices have had an activation attempt
    // If the user has activated one gateway and one sensor, but has not activated all devices, they are not prevented from continuing to the dashboard
    // If the user has activated 2 Leak & Freeze devices or 1 if there are 2, but has not activated all devices, they are not prevented from continuing to the dashboard
    const activeGatewayCount = devicesToCheck.filter(
      d => isGateway(d.nodeTypeName) && getDeviceStatus(d) === 'active'
    ).length
    const activeLoraCount = devicesToCheck.filter(
      d => isNonNbiotSensor(d.nodeTypeName) && getDeviceStatus(d) === 'active'
    ).length
    const activeNbiotCount = devicesToCheck.filter(
      d => isNbiotSensor(d.nodeTypeName) && getDeviceStatus(d) === 'active'
    ).length
    const nbiotCount = devicesToCheck.filter(d => isNbiotSensor(d.nodeTypeName)).length

    return (
      (activeGatewayCount >= 1 && activeLoraCount >= 2) ||
      (nbiotCount > 2 && activeNbiotCount >= 2) ||
      (nbiotCount <= 2 && activeNbiotCount >= 1)
    )
  }
  return false
}

const getGlowIconStatus = (): boolean => {
  try {
    const glowIconStatus = JSON.parse(localStorage.getItem('step1indicatorstatus') || '')
    return !!glowIconStatus.steponeindicatoriconstatus ? glowIconStatus.steponeindicatoriconstatus : false
  } catch {
    return false
  }
}

const Activation = (_: RouteComponentProps) => {
  const _isMounted = useRef(true)
  const { folderId, deviceId }: { folderId: string; deviceId: string } = useParams()
  const handleError = useErrorHandler()
  const { folders, setFolders, foldersLoading, user } = useContext(AppContext)

  // This allow us to cancel an axios call in the useEffect cleanup function when unmounting the component
  const [source] = useState<CancelTokenSource>(axios.CancelToken.source())
  const [loading, setLoading] = useState<boolean>(true)
  const [devices, setDevices] = useState<IDeviceType[]>([])
  const [currentDeviceId, setCurrentDeviceId] = useState<number | undefined>()
  const [deviceLocations, setDeviceLocations] = useState<string[]>(DEFAULT_DEVICE_LOCATIONS)
  const [selectDeviceModal, setSelectDeviceModal] = useState<boolean>(false)
  const [exitActivationModal, setExitActivationModal] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  const [searchResults, setSearchResults] = useState<IDeviceType[]>([])
  const [step1Started, setStep1Started] = useState<boolean>(getGlowIconStatus()) // Toggle the glow icon
  const [tutorialModal, setTutorialModal] = useState<boolean>(true)

  const currentFolder = folders.find(f => f.id === Number(folderId))
  const currentDevice = devices.find(d => d.id === currentDeviceId)
  const gatewayCount = devices.filter(d => isGateway(d.nodeTypeName)).length
  const sensorCount = devices.filter(d => !isGateway(d.nodeTypeName)).length
  const gatewaysActive = devices.filter(d => isGateway(d.nodeTypeName) && d.isActivated).length > 0
  const percentComplete = (devices.filter(d => d.isActivated).length / devices.length) * 100
  const canCompleteActivation = toggleCompleteActivation(devices)
  const isSearching = currentDevice ? deviceIsSearching(currentDevice) : true
  const deviceStepsComplete = currentDevice
    ? !!(!isSearching && currentDevice.deviceUseCase && currentDevice.area && currentDevice.deviceLocationNotes)
    : false

  const prevPlacementRef = useRef({
    building: currentDevice?.building,
    floor: currentDevice?.floor,
    area: currentDevice?.area,
    deviceLocationNotes: currentDevice?.deviceLocationNotes,
    deviceUseCase: currentDevice?.deviceUseCase,
  })

  // Websockets
  const updateState = useCallback((props: UpdateStateProps) => {
    const { payload, channelValue, timestamp, deviceId: messageDeviceId } = props
    const { channelName, value } = channelValue
    const newChannelValue: ICurrentValueType = {}
    newChannelValue[channelName] = {
      createdAt: timestamp,
      timestamp,
      value,
    }
    setDevices(prev =>
      prev.map(d => {
        if (d.id === messageDeviceId)
          return {
            ...d,
            ...payload,
            currentValues: {
              ...d.currentValues,
              ...newChannelValue,
            } as unknown as ICurrentValueType,
          }
        else return d
      })
    )
  }, [])
  useWebsocket({
    shouldBeAlive: _isMounted.current,
    subscribeIds: devices.map(d => d.id),
    updateState,
    subscribeChannels,
    subscribeEvent: false,
  })

  // Get all devices in selected folder
  const fetchData = useCallback(async () => {
    if (folderId) {
      try {
        const apiData = await getDevices(Number(folderId), true, source)
        if (apiData.length === 0) {
          // There are no devices in this folder: activate the folder and navigate to the dashboard
          await API.post(`/api/v2/folders/${folderId}/activation`, {})
          navigate(`/dashboard/${folderId}`)
        }
        setDevices(apiData)
        const apiTheme = (
          await API.get(`/api/folders/${folderId}/theme`, {
            cancelToken: source.token,
          })
        ).data.theme
        const newDeviceLocations = apiTheme.deviceLocations
          ? typeof apiTheme.deviceLocations === 'string'
            ? convertStringToArray(apiTheme.deviceLocations)
            : apiTheme.deviceLocations
          : DEFAULT_DEVICE_LOCATIONS
        setDeviceLocations(newDeviceLocations)
        setLoading(false)
      } catch (e) {
        if (e.message !== 'cancelled') handleError(e)
      }
    }
  }, [folderId, handleError, source])

  const isLoading = useCallback(() => {
    return foldersLoading || loading
  }, [foldersLoading, loading])

  // Clean up when the component unmounts
  useEffect(() => {
    return () => {
      // clean up axios calls when component unmounts
      source.cancel('cancelled')
      _isMounted.current = false // used to handle memory leaks when performing a state changing when the component has already unmounted
    }
  }, [source])

  // If deviceId is set, select the device if it is found
  useEffect(() => {
    if (!deviceId || foldersLoading || loading) return // Do nothing if no deviceId is set or there are no devices yet
    if (deviceId && devices.find(d => d.id === Number(deviceId))) {
      setCurrentDeviceId(Number(deviceId))
    } else handleError(new Error(`This device was not found.`))
  }, [deviceId, devices, folderId, foldersLoading, loading, handleError])

  // Handle no folderId or folderId user does not have access to
  useEffect(() => {
    if (!foldersLoading) {
      if (!folderId || !currentFolder || currentFolder.folderType !== 'Participant') {
        // If there is no folderId passed to activation, send user to Login Redirect
        // If there is a folderId but it is not one of the user's folders, send user to Login Redirect
        // If folder is not a Participant folder, send user to Login Redirect (otherwise the devices endpoint will error)
        navigate(`/login-redirect`)
      } else {
        fetchData()
      }
    }
  }, [folderId, folders, foldersLoading, loading, currentFolder, fetchData])

  // If a placement property changes, send a put request to the API
  // This seems a little convoluted and wordy with the ref usage, but I'm trying to avoid unnecessary API calls if I run this effect anytime currentDevice changes 😬
  useEffect(() => {
    const saveToApi = async () => {
      if (currentDevice?.id) {
        if (
          prevPlacementRef.current.building !== currentDevice.building ||
          prevPlacementRef.current.floor !== currentDevice.floor ||
          prevPlacementRef.current.area !== currentDevice.area ||
          prevPlacementRef.current.deviceLocationNotes !== currentDevice.deviceLocationNotes ||
          prevPlacementRef.current.deviceUseCase !== currentDevice.deviceUseCase ||
          (!currentDevice.isActivated && deviceStepsComplete)
        ) {
          const payload = {
            building: currentDevice.building,
            floor: currentDevice.floor,
            area: currentDevice.area,
            deviceLocationNotes: currentDevice.deviceLocationNotes,
            deviceUseCase: currentDevice.deviceUseCase,
            isActivated: deviceStepsComplete,
          }
          // Save to API
          try {
            await API.patch(`/api/v2/protect/devices/${currentDevice.id}`, payload)
            if (!currentDevice?.isActivated && deviceStepsComplete)
              setDevices(prev =>
                prev.map(d => {
                  if (d.id === currentDevice.id) {
                    return { ...d, isActivated: true }
                  } else return d
                })
              )
          } catch (e) {
            handleError(e)
          }

          prevPlacementRef.current = {
            building: currentDevice.building,
            floor: currentDevice.floor,
            area: currentDevice.area,
            deviceLocationNotes: currentDevice.deviceLocationNotes,
            deviceUseCase: currentDevice.deviceUseCase,
          }
        }
      }
    }

    saveToApi()
  }, [
    currentDevice?.id,
    currentDevice?.isActivated,
    currentDevice?.building,
    currentDevice?.floor,
    currentDevice?.area,
    currentDevice?.deviceLocationNotes,
    currentDevice?.deviceUseCase,
    isSearching,
    deviceStepsComplete,
    handleError,
  ])

  // Filter devices when search text changes
  useEffect(() => {
    const parseString = (str: string) => str.toLowerCase().replace(/-/g, '')

    setSearchResults(
      devices.filter(
        device =>
          parseString(device.uniqueId).includes(parseString(searchText)) ||
          parseString(device.vanity).includes(parseString(searchText))
      )
    )
  }, [devices, searchText, folderId])

  // Toggle Blue glow icon on the sensor card, save the status to localstorage.
  const setStep1StartedAndRemember = (toggleStatus: boolean): void => {
    setStep1Started(toggleStatus)
    localStorage.setItem(
      'step1indicatorstatus',
      JSON.stringify({
        userId: user?.id,
        steponeindicatoriconstatus: toggleStatus,
      })
    )
  }

  const selectDevice = (id: number) => {
    if (devices) {
      const updatedCurrentSensor = devices.find(d => d.id === id)

      // Update the placement property ref so we can keep track of changes that require an API PUT call
      if (updatedCurrentSensor) {
        prevPlacementRef.current = {
          building: updatedCurrentSensor.building,
          floor: updatedCurrentSensor.floor,
          area: updatedCurrentSensor.area,
          deviceLocationNotes: updatedCurrentSensor.deviceLocationNotes,
          deviceUseCase: updatedCurrentSensor.deviceUseCase,
        }
      }
      setCurrentDeviceId(id)
      // Remove the Glow icon once a device is selected.
      setStep1StartedAndRemember(true)
      window.scrollTo(0, 0)
    }
  }

  const updateSensorProperty = (
    id: number,
    property: 'area' | 'building' | 'floor' | 'deviceLocationNotes' | 'deviceUseCase',
    value: string
  ) => {
    setDevices(prev =>
      prev.map(d => {
        // Set the new value for the property update
        // @ts-ignore
        if (d.id === id) d[property] = value
        // If all required fields are filled in and sensor is communicating, make the sensor active
        if (d.id === id && !deviceIsSearching(d) && deviceDataIsComplete(d)) {
          d.isActivated = true
        } else if (d.id === id && !deviceDataIsComplete(d)) {
          d.isActivated = false
        }
        return d
      })
    )
  }

  const completeActivation = async () => {
    // Update the `activation` property on the folder
    setFolders(prevFolders => {
      return (
        prevFolders &&
        prevFolders.map(f => {
          if (f.id === Number(folderId)) {
            return {
              ...f,
              isActivated: true,
            }
          } else return f
        })
      )
    })

    // Activate the folder, mark remaining devices as extra, invalidate user tokens
    await API.post(`/api/v2/folders/${folderId}/activation`, {})
    navigate(`/dashboard/${folderId}`)
  }

  // Handle mobile nav change
  const handleBack = () => {
    setCurrentDeviceId(undefined)
    navigate(`/activating/${folderId}`)
  }

  return (
    <ActivationContext.Provider value={{ devices, setDevices }}>
      <StyledSkipLinksActivation
        onClick={() => {
          document.getElementById('mainContent')?.setAttribute('tabindex', '-1')
          document.getElementById('mainContent')?.focus()
        }}
      >
        Skip to Main Content
      </StyledSkipLinksActivation>
      {folderId && !deviceId && !isLoading() && devices && devices.length > 0 && percentComplete < 100 && (
        <Modal
          title="Activation Tutorial"
          openModal={tutorialModal}
          onDismiss={() => setTutorialModal(false)}
          maxWidth="72.6rem"
          roundedCorners={true}
          shadows={true}
          textalign="center"
          showTitleAboveDivider={true}
          showDivider={true}
          data-testid="activation-modal"
        >
          <CarouselTutorial numberOfSlides={3} autoplay={false} onClose={() => setTutorialModal(false)}>
            <TutorialSlide>
              <img src={Step1} alt="" />
              <h3>Select Device</h3>
              <p>Search by your device’s 4 or 7 digit code found on the sticker to get started.</p>
            </TutorialSlide>
            <TutorialSlide>
              <img src={Step2} alt="" />
              <h3>Choose Location</h3>
              <p>Follow the guidelines to choose the ideal location for your device. </p>
            </TutorialSlide>
            <TutorialSlide>
              <img src={Step3} alt="" />
              <h3>Activate & Place</h3>
              <p>Follow the step-by-step directions to activate and place your devices to protect your location. </p>
            </TutorialSlide>
          </CarouselTutorial>
        </Modal>
      )}
      <Header
        type="activating"
        locationName={currentFolder?.name}
        handleBack={!!currentDevice ? handleBack : undefined}
      />
      <ProgressBar percentComplete={percentComplete} />
      <PageLayout>
        <Sidebar mobilenavsection={!currentDevice ? 'sidebar' : 'content'} data-testid="sidebar">
          {(!devices || isLoading()) && (
            <>
              <SidebarHeader>
                <SkeletonLine rounded={true} maxWidth="245px" />
              </SidebarHeader>
              <SensorSelectSkeleton />
              <SensorSelectSkeleton />
              <SensorSelectSkeleton />
            </>
          )}
          {!isLoading() && devices && devices.length === 0 && <>There are no devices registered to this location.</>}
          {!isLoading() && devices && devices.length > 0 && (
            <>
              <SidebarHeaderContainer>
                <SidebarHeader>Select Device</SidebarHeader>
                <DesktopOnly>
                  <Btn buttonType="link" onClick={() => setSelectDeviceModal(true)}>
                    Show me how
                  </Btn>
                </DesktopOnly>
              </SidebarHeaderContainer>

              <DesktopOnly>
                <SearchContainer>
                  <SearchBar
                    searchText={searchText}
                    setSearchText={setSearchText}
                    placeHolderText="Search by ID or name"
                  />
                </SearchContainer>
              </DesktopOnly>
              <MobileInstructions>
                Select a device to start activation. More about{' '}
                <LinkButton onClick={() => setSelectDeviceModal(true)}>selecting a device</LinkButton>.
                <Sidepanel
                  title="Selecting a device"
                  showMobileTitleAboveDivider={true}
                  openModal={selectDeviceModal}
                  onDismiss={() => setSelectDeviceModal(false)}
                >
                  <DesktopOnly>
                    <LeftAlignParagraph>
                      Match the ID number found on the sticker to one of the devices on the list. You can also enter the
                      ID number in the search field to quickly locate the device.
                    </LeftAlignParagraph>
                  </DesktopOnly>
                  <MobileOnly>
                    <LeftAlignParagraph>
                      Match the device number found on the sticker to one of the devices on the list. You can also enter
                      the ID number in the search field to quickly locate the device.
                    </LeftAlignParagraph>
                  </MobileOnly>

                  <LeftAlignParagraph>
                    The sticker location may vary by different types of device, but it’s typically found on the sides or
                    bottom.
                  </LeftAlignParagraph>
                  <InstructionalImageContainer>
                    <img src={SensorSerialInfo} alt="" />
                  </InstructionalImageContainer>
                  <MobileModalButtonContainer>
                    <Btn buttonType="ghost" onClick={() => setSelectDeviceModal(false)}>
                      Got It
                    </Btn>
                  </MobileModalButtonContainer>
                </Sidepanel>
              </MobileInstructions>
              <MobileOnly>
                <SidebarSearchSection>
                  <SearchBar
                    searchText={searchText}
                    setSearchText={setSearchText}
                    placeHolderText="Search by ID or name"
                  />
                </SidebarSearchSection>
              </MobileOnly>
              {searchText ? (
                <>
                  <SearchResultsHeading className="fs-exclude">
                    Search Results ({searchResults.length})
                  </SearchResultsHeading>
                  {searchResults.filter(
                    device => device.nodeTypeName !== 'gateway' && device.nodeTypeName !== 'nbiot_leak'
                  ).length > 0 && (
                    <Alert
                      icon="solid_info"
                      iconAlignment="center"
                      message="Gateway must be activated first."
                      type="custom"
                      backgroundcolor="rgba(51, 135, 178, 0.2)"
                    />
                  )}
                  {searchResults.length > 0 ? (
                    searchResults.map(device => (
                      <SensorSelectContainer key={device.id}>
                        <SensorSelect
                          id={device.id}
                          useCase={device.deviceUseCase}
                          name={device.vanity}
                          status={getDeviceStatus(device)}
                          disabled={
                            gatewayCount > 0 &&
                            !gatewaysActive &&
                            device.nodeTypeName !== 'nbiot_leak' &&
                            device.nodeTypeName !== 'gateway'
                          }
                          selected={device.id === currentDeviceId}
                          onSelect={selectDevice}
                        />
                      </SensorSelectContainer>
                    ))
                  ) : (
                    <NoSearchResults className="fs-exclude">
                      We couldn’t find any results matching “{searchText}”. Please check spelling or try again with
                      another term.
                    </NoSearchResults>
                  )}
                </>
              ) : (
                <>
                  {gatewayCount > 0 && (
                    <>
                      <SidebarSubHeader>
                        <div>Gateway</div>
                        <SidebarSubHeaderNote>(Activate first)</SidebarSubHeaderNote>
                      </SidebarSubHeader>
                      {devices &&
                        gatewayCount > 0 &&
                        [...devices]
                          .sort((a, b) => (a.uniqueId > b.uniqueId ? 1 : -1))
                          .filter(device => device.nodeTypeName === 'gateway')
                          .map((device, index) => {
                            return (
                              <SensorSelectContainer key={device.id}>
                                <SensorSelect
                                  id={device.id}
                                  useCase={device.deviceUseCase}
                                  name={device.vanity}
                                  status={getDeviceStatus(device)}
                                  disabled={false}
                                  selected={device.id === currentDeviceId}
                                  onSelect={selectDevice}
                                />
                                {index === 0 &&
                                  (getDeviceStatus(device) === 'inactive' ||
                                    getDeviceStatus(device) === 'incomplete') &&
                                  !step1Started && (
                                    <div
                                      style={{
                                        position: 'absolute',
                                        top: 'calc(50% - 53px / 2)',
                                        right: '-20px',
                                      }}
                                    >
                                      <Tooltip
                                        icon={<GlowCallout />}
                                        alignment="top"
                                        maxWidth={192}
                                        ariaLabel="View Tooltip for Help"
                                      >
                                        <p>You must first activate the gateway in order to connect other devices.</p>
                                      </Tooltip>
                                    </div>
                                  )}
                              </SensorSelectContainer>
                            )
                          })}
                    </>
                  )}

                  <SidebarSubHeader disabled={gatewayCount > 0 && !gatewaysActive}>Sensors</SidebarSubHeader>
                  {devices &&
                    sensorCount > 0 &&
                    [...devices]
                      .sort((a, b) => (a.uniqueId > b.uniqueId ? 1 : -1))
                      .filter(device => device.nodeTypeName !== 'gateway')
                      .map((device, index) => {
                        return (
                          <SensorSelectContainer key={device.id}>
                            <SensorSelect
                              id={device.id}
                              useCase={device.deviceUseCase}
                              name={device.vanity}
                              status={getDeviceStatus(device)}
                              disabled={gatewayCount > 0 && !gatewaysActive && device.nodeTypeName !== 'nbiot_leak'}
                              selected={device.id === currentDeviceId}
                              onSelect={selectDevice}
                            />
                            {index === 0 &&
                              gatewayCount === 0 &&
                              (getDeviceStatus(device) === 'inactive' || getDeviceStatus(device) === 'incomplete') &&
                              !step1Started && (
                                <TooltipContainer>
                                  <Tooltip
                                    icon={<GlowCallout />}
                                    alignment="top"
                                    maxWidth={192}
                                    ariaLabel="View Tooltip for Help"
                                  >
                                    <p>Start by selecting one of the sensors.</p>
                                  </Tooltip>
                                </TooltipContainer>
                              )}
                          </SensorSelectContainer>
                        )
                      })}
                </>
              )}
            </>
          )}
        </Sidebar>
        {(!devices || isLoading()) && <Loading />}
        {!isLoading() && devices && devices.length > 0 && (
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={errorHandler}>
            <>
              {!currentDevice && <Welcome />}
              {currentDevice && (
                <SensorActivation
                  device={currentDevice}
                  deviceLocations={deviceLocations}
                  handleBack={handleBack}
                  updateSensorProperty={updateSensorProperty}
                  key={currentDevice.id}
                />
              )}
              {percentComplete === 100 && <CompletionPanel devices={devices} completeActivation={completeActivation} />}
            </>
          </ErrorBoundary>
        )}

        {!isLoading() && !!currentFolder && devices && devices.length > 0 && (
          <ActivationCTAPanel isDeviceScreen={!!currentDevice}>
            <CTAPanelLeft>
              <DesktopOnly>
                <strong className="fs-exclude">Activating | {makeEllipsis(currentFolder.name)}</strong>
                {hasMultiFolderAccess(folders) && (
                  <ExitButton
                    onClick={e => {
                      e.preventDefault()
                      navigate('/dashboard')
                    }}
                  >
                    Exit and setup later
                  </ExitButton>
                )}
              </DesktopOnly>
              <MobileOnly>
                {hasMultiFolderAccess(folders) && (
                  <ExitButton
                    onClick={e => {
                      e.preventDefault()
                      navigate('/dashboard')
                    }}
                  >
                    Setup Later
                  </ExitButton>
                )}
              </MobileOnly>
            </CTAPanelLeft>
            <ActivationCTAPanelRight>
              <Btn
                buttonType="ghost"
                onClick={() => {
                  setExitActivationModal(true)
                }}
                disabled={!!!canCompleteActivation}
              >
                Complete Activation
              </Btn>
            </ActivationCTAPanelRight>
          </ActivationCTAPanel>
        )}

        {currentFolder && (
          <Modal
            title="Finalize Activation?"
            openModal={exitActivationModal}
            onDismiss={() => setExitActivationModal(false)}
            maxWidth="72.6rem"
            textalign="left"
            mobileBackground={theme.colors.ui02}
            showDivider={true}
            showDividerMobile={true}
            showTitleAboveDivider={true}
            roundedCorners={true}
            shadows={true}
          >
            <ModalContent folderId={folderId} completeActivation={completeActivation} />
          </Modal>
        )}
      </PageLayout>
    </ActivationContext.Provider>
  )
}

export default Activation
